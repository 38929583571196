//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    kytStatus: { type: Number, default: undefined },
  },

  data() {
    return {
      comment: '',
      details: '',
      address: '',
    };
  },

  computed: {
    ...mapGetters('login', ['getCurrentAdmin']),
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    detailsInput() {
      return {
        show: true,
      };
    },

    commentInput() {
      const { transactionStatus, side } = this.data;
      const {
        PROCESSING,
        ADMIN_PROCESSING,
        // AWAITING_MANUAL_CHECK,
        ADMIN_CHECK,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      return {
        show: (
          ([PROCESSING, ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
          || ([
            PROCESSING,
            ADMIN_PROCESSING,
            // AWAITING_MANUAL_CHECK,
            ADMIN_CHECK,
          ].includes(transactionStatus) && side === IN)
        ),
      };
    },

    processingButton() {
      const { transactionStatus } = this.data;
      const {
        AWAITING_MANUAL_CHECK,
        ADMIN_CHECK,
        NEED_CONTACT_SUPPORT,
        IN_QUEUE,
      } = constant.operations.TRANSACTION_STATUS;
      const { details } = this;
      return {
        show: [
          AWAITING_MANUAL_CHECK,
          ADMIN_CHECK,
          NEED_CONTACT_SUPPORT,
          IN_QUEUE,
        ].includes(transactionStatus),
        disabled: !details,
        title: this.$t('Start processing'),
        className: 'primary--text',
        callback: {
          name: 'processing',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
          },
        },
      };
    },

    confirmButton() {
      const { status, side } = this.data;
      const { WITHDRAWAL } = constant.operations.TRANSACTION_TYPE;
      const { AWAITING, ERROR } = constant.traders.TRANSACTION_STATUS_CODES;
      const { comment, details } = this;
      const show = (status === AWAITING || status === ERROR) && side === WITHDRAWAL;
      return {
        show,
        disabled: !details,
        title: this.$t('In withdrawal queue'),
        className: 'success--text',
        callback: {
          name: 'confirm',
          params: {
            ...this.data,
            id: String(this.data.id),
            comment,
            details,
          },
        },
      };
    },

    approveKYTButton() {
      const { transactionStatus, side } = this.data;
      const {
        ADMIN_PROCESSING,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { details } = this;
      const show = (
        (([ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          ADMIN_PROCESSING,
        ].includes(transactionStatus) && side === IN)) && this.$props.kytStatus > 0
      );
      return {
        show,
        disabled: !details,
        title: this.$t('Approve KYT'),
        className: 'success--text',
        callback: {
          name: 'approve',
          params: {
            ...this.data,
            details: this.details,
            transactionId: this.data.id,
          },
        },
      };
    },

    completeButton() {
      const { status, side } = this.data;
      const { DEPOSIT, WITHDRAWAL } = constant.operations.TRANSACTION_TYPE;
      const { AWAITING, ERROR } = constant.traders.TRANSACTION_STATUS_CODES;
      const { comment, details } = this;
      const show = (status === AWAITING || status === ERROR) && (side === DEPOSIT || side === WITHDRAWAL);
      const title = this.$t('Complete');
      return {
        show,
        disabled: !details,
        title,
        className: 'success--text',
        callback: {
          name: 'complete',
          params: {
            ...this.data,
            id: String(this.data.id),
            comment,
            details,
          },
        },
      };
    },

    cancelButton() {
      const { status } = this.data;
      const { AWAITING, ERROR, FROZEN } = constant.traders.TRANSACTION_STATUS_CODES;
      const { details } = this;
      const show = status === AWAITING
      || status === ERROR
      || status === FROZEN;
      return {
        show,
        disabled: !details,
        title: this.$t('Cancel'),
        className: 'error--text',
        callback: {
          name: 'cancel',
          params: {
            ...this.data,
            id: String(this.data.id),
            comment: details,
          },
        },
      };
    },

    declineButton() {
      const { transactionStatus, side } = this.data;
      const {
        PROCESSING,
        ADMIN_PROCESSING,
        // AWAITING_MANUAL_CHECK,
        ADMIN_CHECK,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { comment, details } = this;
      const show = (
        (([PROCESSING, ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          PROCESSING,
          ADMIN_PROCESSING,
          // AWAITING_MANUAL_CHECK,
          ADMIN_CHECK,
        ].includes(transactionStatus) && side === IN)) && (this.$props.kytStatus === undefined || this.$props.kytStatus === 0)
      );
      return {
        show,
        disabled: !comment || !details,
        title: this.$t('Decline'),
        className: 'error--text',
        callback: {
          name: 'decline',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
            comment: this.comment,
          },
        },
      };
    },

    refuseKYTButton() {
      const { transactionStatus, side } = this.data;
      const {
        ADMIN_PROCESSING,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { comment, details } = this;
      const show = (
        (([ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          ADMIN_PROCESSING,
        ].includes(transactionStatus) && side === IN)) && this.$props.kytStatus > 0
      );
      return {
        show,
        disabled: !comment || !details,
        title: this.$t('Refuse'),
        className: 'error--text',
        callback: {
          name: 'refuse',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
            comment: this.comment,
          },
        },
      };
    },

    needContactButton() {
      const { transactionStatus } = this.data;
      const { ADMIN_PROCESSING } = constant.operations.TRANSACTION_STATUS;
      const { details } = this;
      return {
        show: [ADMIN_PROCESSING].includes(transactionStatus),
        disabled: !details,
        title: this.$t('Need contact'),
        className: 'primary--text',
        callback: {
          name: 'needContact',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
          },
        },
      };
    },

    checkButton() {
      const { transactionStatus } = this.data;
      const { PROCESSING, AWAITING_CONFIRMATION } = constant.operations.TRANSACTION_STATUS;
      return {
        show: [PROCESSING, AWAITING_CONFIRMATION].includes(transactionStatus),
        disabled: false,
        title: this.$t('Admin check'),
        className: 'primary--text',
        callback: {
          name: 'check',
          params: {
            ...this.data,
            transactionId: this.data.id,
          },
        },
      };
    },

    cancelCompletedButton() {
      const { transactionStatus, side } = this.data;
      const { COMPLETED } = constant.operations.TRANSACTION_STATUS;
      const { IN } = constant.operations.OPERATION_SIDE;
      const { details } = this;
      return {
        show: [COMPLETED].includes(transactionStatus) && side === IN,
        disabled: !details,
        title: this.$t('Cancel completed'),
        className: 'error--text',
        callback: {
          name: 'cancelCompleted',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
          },
        },
      };
    },

    buttonList() {
      return [
        this.processingButton,
        this.confirmButton,
        this.completeButton,
        this.cancelButton,
        this.declineButton,
        this.needContactButton,
        this.checkButton,
        this.cancelCompletedButton,
        this.approveKYTButton,
        this.refuseKYTButton,
      ].filter((e) => e.show);
    },
  },

  watch: {
    value(value) {
      if (!value) {
        this.comment = '';
        this.details = '';
      }
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction']),

    clickHandler({ name, params }) {
      this.confirmAction({
        text: 'Are you sure?',
        callback: () => {
          this.$emit(name, params);
          this.$emit('input', false);
        },
      });
    },
  },
};
